<template>
    <div class="card">
        <div class="card-body p-0">
            <div class="card-px text-center py-20 my-10">
                <h2 class="fs-2x fw-bold mb-10">{{ $t('pages.statistic.lesson.title') }}</h2>
                <p class="text-gray-500 fs-5 fw-semobold mb-13">
                    {{ $t('pages.statistic.lesson.filter.description') }}
                </p>
                <div class="m-auto mb-10">
                    <el-select class="w-50" :placeholder="$t('pages.module.lecture.selectProduct.chooseProduct')" v-model="filter.data.products" filterable multiple>
                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                    </el-select>
                </div>
                <button :data-kt-indicator="filter.loading ? 'on' : null" class="btn btn-primary er fs-6 px-8 py-4" type="button" @click="onFilter" :disabled="filter.loading">
                    <span v-if="!filter.loading" class="indicator-label">{{ $t('pages.statistic.lesson.filter.button') }}</span>
                    <span v-if="filter.loading" class="indicator-progress">
                        {{ $t("messages.wait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
        </div>
    </div>
    <StatisticLesson class="mt-10" ref="lessonStatistic" :products="filter.data.products" @loading="filter.loading = $event"></StatisticLesson>
</template>

<script>
import StatisticLesson from "@/components/statistic/lesson";

export default {
    name: "index",
    components: {
        StatisticLesson
    },
    data(){
        return {
            filter: {
                loading: false,
                data: {},
            },
            products: []
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t('pages.statistic.lesson.title'), [this.$t('menu.statistic.title')]);

        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: { pageSize: 9999 }
        }).then((products) => {
            let tempProducts = [];

            products.forEach((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id');
                if (product.can_use_module) {
                    tempProducts.push(product);
                }
            });

            this.products = tempProducts;
        });
    },
    methods: {
        onFilter(){
            if(!this.filter.data.products ||!Array.isArray(this.filter.data.products) || !this.filter.data.products.length){
                this.$notify({
                    type: 'warning',
                    title: this.$t("messages.warning"),
                    message: this.$t("pages.statistic.lesson.warning.requiredProduct"),
                })

                return;
            }

            this.$refs.lessonStatistic.loadStatistic();
        },
    }
}
</script>

<style scoped>

</style>