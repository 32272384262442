<template>
    <div class="card" v-if="loading">
        <div class="card-body d-flex flex-column p-9">
            <el-skeleton />
        </div>
    </div>
    <div class="lesson-statistic w-100" v-else>
        <div class="lesson-statistic-item" v-for="(lessonStatistic, lessonStatisticIndex) in lessonStatistics" :key="lessonStatisticIndex" :class="lessonStatisticIndex != 0 && 'mt-7'">
            <div class="lesson-statistic-info" v-if="showProductTitle">
                <div class="d-flex align-items-center cursor-pointer" @click="showProduct(lessonStatistic.id)">
                    <span class="expandColumn cursor-pointer collapsible rotate" v-bind:class="{collapsed: showProductID != lessonStatistic.id}">
                        <div class="me-3 rotate-90">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/arrows/arr071.svg" />
                            </span>
                        </div>
                    </span>
                    <h2 class="mb-0">{{ sprintf('%s#%d', [lessonStatistic.title, lessonStatistic.id]) }}</h2>
                </div>
                <div class="separator separator-dashed mt-3 mb-7 border-gray-400"></div>
            </div>
            <template v-if="showProductID == lessonStatistic.id">
                <div class="lesson-statistic-detail" v-if="lessonStatistic.is_statistic_available">
                    <div class="d-flex justify-content-end mb-5">
                        <el-tooltip :content="sprintf($t('pages.statistic.lesson.userWatchLesson.exportTooltip'), [lessonStatistic.title])" popper-class="max-w-300px" placement="bottom">
                            <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportExcel(lessonStatistic.id, 'user_watch_lesson')">
                                <span v-if="!exportLoading" class="indicator-label">
                                    <span class="svg-icon svg-icon-1">
                                        <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                                    </span>
                                    {{ $t("common.export") }}
                                </span>

                                <span v-if="exportLoading" class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                                    {{ $t("messages.wait") }}
                                </span>
                            </button>
                        </el-tooltip>
                    </div>
                    <custom-table
                        :runModeServer="false"
                        :title="sprintf($t('pages.statistic.lesson.userWatchLesson.title'), [lessonStatistic.title])"
                        :subTitle="$t('pages.statistic.lesson.userWatchLesson.subTitle')"
                        :items="lessonStatistic.statistic.user_watch_lesson"
                        :columns="user_watch_lesson.fields"
                        :pagination="lessonStatistic.userWatchPagination"
                        filterComponentName="StatisticLessonFilter">
                        <template v-slot:user="{ row: record }">
                            <router-link :to="'/ecommerce/user?userID=' + record.id" class="cursor-pointer text-gray-600 text-hover-primary" target="_blank">
                                <span class="fw-bolder">{{ record.full_name }}</span>
                            </router-link>
                            <br>
                            <a :href="'mailto:' + record.email" class="text-gray-600 text-hover-primary mb-1 cursor-pointer">
                                {{ record.email }}
                            </a>
                        </template>
                        <template v-slot:statistic="{ row: record }">
                            {{ lessonStatistic.lecture_count }}/{{ record.watched_lecture_count }}
                        </template>
                        <template v-slot:watchPercent="{ row: record }">
                            <el-progress :text-inside="true" :stroke-width="22" :percentage="record.watched_percentage" />
                        </template>
                    </custom-table>
                </div>
                <div v-else class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                    <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                    </span>
                    <div class="d-flex flex-stack flex-grow-1">
                        <div class="fw-bold">
                            <h4 class="text-gray-900 fw-bold">{{ $t('pages.statistic.lesson.warning.notFoundStatistic.title')}}</h4>
                            <div class="fs-6 text-gray-600">{{ $t('pages.statistic.lesson.warning.notFoundStatistic.description')}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    props: {
        products: {type: Array, default: () => { return []}},
        showProductTitle: {type: Boolean, default: true},
    },
    components: {
        CustomTable
    },
    data(){
        return {
            user_watch_lesson: {
                fields: [
                    {
                        name: this.$t("pages.statistic.lesson.userWatchLesson.cols.user"),
                        scopedSlots: {customRender: "user"},
                    },
                    {
                        name: this.$t("pages.statistic.lesson.userWatchLesson.cols.statistic"),
                        scopedSlots: {customRender: "statistic"},
                    },
                    {
                        name: this.$t("pages.statistic.lesson.userWatchLesson.cols.watchPercent"),
                        scopedSlots: {customRender: "watchPercent"},
                    },
                ]
            },
            lessonStatistics: [],
            loading: false,
            showProductID: undefined,
            exportLoading: false,
        }
    },
    methods: {
        loadStatistic(){
            if(!this.products ||!Array.isArray(this.products) || !this.products.length){
                return;
            }

            this.setLoading(true);

            this.axios.post(this.endpoints['statistic_lesson'], {products: this.products}).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;

                    this.lessonStatistics = data.map((product) => {
                        let status = false;
                        for (let statisticKey in product.statistic){
                            let statistic = product.statistic[statisticKey];
                            if(statistic) {
                                if(typeof(statistic) == 'object') {
                                    if(Array.isArray(statistic)) {
                                        status = statistic.length > 0;
                                    } else {
                                        for(let key in statistic) {
                                            let data = statistic[key];
                                            if(data) {
                                                status = true;
                                            }
                                        }
                                    }
                                } else {
                                    status = true;
                                }
                            }
                        }

                        product.is_statistic_available = status;

                        product.userWatchPagination = {
                            current: 1,
                            pageSize: 10,
                            total: product.statistic.user_watch_lesson ? product.statistic.user_watch_lesson.length : 0,
                        }

                        return product;
                    });

                    if(data.length){
                        this.showProductID = data[0].id;
                    }
                }, undefined, false);

            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.setLoading(false);
            });
        },
        showProduct(productID){
            this.showProductID = this.showProductID != productID ? productID : undefined;
        },
        setLoading(status) {
            this.$emit('loading', status);
            this.loading = status;
        },
        exportExcel(productID, statisticKey) {
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['statistic_lesson_export'],
                method: 'post',
                responseType: "blob",
                data: {
                    product_id: productID,
                    statistic_key: statisticKey
                }
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        },
    }
}
</script>

<style scoped>

</style>